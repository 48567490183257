import { Confetti } from 'components/Elements';
import useLocalStorage from 'hooks/useLocalStorage';
import React, { createContext, useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { THEME_TYPE  } from 'types';


const Context = createContext({
    theme: null,
    view: null,
    isDark: false,
    toggleNavbar: () => {},
    selectTheme: () => { }
});



export const ThemeProvider = ({ children }) => {
    
    const [theme, setTheme] = useLocalStorage('themeMode', THEME_TYPE.LIGHT_MODE)
    const [open, setOpen] = useLocalStorage('sidebar', false)
    const [showConfetti, setShowConfetti] = useState(false)

    const selectTheme = (theme) => {
        setTheme(theme);
    };

    const celebrate = () => {
        setShowConfetti(true);
        setTimeout(() => {
            setShowConfetti(false);
        }, 5000);
    }


    const getTheme = () => {
        if(theme === THEME_TYPE.LIGHT_MODE) {
            return '/assets/css/theme.css'
        }
        if(theme === THEME_TYPE.DARK_MODE) {
            return '/assets/css/theme-dark.css'
        }
        if(theme === THEME_TYPE.SYSTEM_DEFAULT) {
            const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)").matches;
            if(darkThemeMq) {
                return '/assets/css/theme-dark.css'
            } else {
                return '/assets/css/theme.css'
            }
        } 
        return '/assets/css/theme.css'
    }


    return (
        <Context.Provider value={{ 
            theme, 
            selectTheme,
            isDark: theme === THEME_TYPE.DARK_MODE,
            toggleNavbar: () => setOpen(!open),
            celebrate
        }}>
            <Helmet 
                link={
                    [{
                        "rel": "stylesheet", 
                        type:"text/css", 
                        href: window.location.origin + '/assets/css/theme.css'
                    }, {
                        "rel": "stylesheet", 
                        type:"text/css", 
                        href: window.location.origin + getTheme()
                    }]
                }
                style = {[{
                    "cssText": `
                        [data-hs-theme-appearance]:not([data-hs-theme-appearance='${theme === THEME_TYPE.DARK_MODE ? 'dark' : 'default'}']) {
                            display: none!important;
                        }
                    `
                }]}
            />
            {children}
            {showConfetti && <Confetti />}
        </Context.Provider>
    );

};

export const useTheme = () => useContext(Context);
