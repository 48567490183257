import React from 'react'

export const Spinner = ({className}) => {
    return (
        <div className={className}>
            <span className={'spinner-border'}></span>
        </div>
    )
}


export const FullPageLoading = () => {
    return (
        <span className='d-flex align-items-center justify-content-center h-100 py-10 my-10 w-100 '>
            <span className='spinner-border spinner-border-lg'></span>
        </span>
    )
}



export const AuthPageLogin = () => {
    return (
        <span className='d-flex align-items-center justify-content-center' style = {{minWidth: '100vw', minHeight: '100vh'}}>
            <span className='spinner-border spinner-border-lg'></span>
        </span>
    )
}


export const WaveSpinner = () => {
    return (
        <span className='d-block loader-icecream'></span>
    )
}




export const TableSpinner = () => {
    return (
        <span className='d-flex align-items-center justify-content-center w-100 py-10' style = {{}}>
            <img src = 'https://cdn.dribbble.com/users/108183/screenshots/4543219/loader_backinout.gif' style = {{width: '400px'}}/>
        </span>
    )
}
