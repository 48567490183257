import clsx from 'clsx'
import useAnimate from 'hooks/useAnimate'
import useLockBodyScroll from 'hooks/useLockBodyScroll'
import React from 'react'

export const CenteredModal = ({show, setShow, imageTop, width, title, size, footer, children}) => {

    const {ref} = useAnimate()
    useLockBodyScroll(show)

    return (
        <>
        <div 
            className={clsx("modal fade", show && 'show d-block', size && "modal-"+size)} 
            tabindex="-1" 
            role="dialog" 
            onClick={() => setShow(false)}
            
        >
            <div className="modal-dialog  modal-dialog-centered" role="document"  onClick={() => setShow(false)} style = {width ? {width: width} : {}}>
                <div className="modal-content position-relative shadow-lg" ref = {ref} onClick={e => e.stopPropagation()}>
                    {show && <div>
                        {title && <div className="modal-header border-bottom pb-3 mt-n2">
                            <h5 className="modal-title h3 mb-0">{title}</h5>
                            <div className="btn-xs btn-close pointer" onClick={() => setShow(false)}></div>
                        </div>}
                        {(!title && setShow) && <div className='position-absolute end-0 p-4' style={{zIndex:'11'}}>
                            <div className="btn-xs btn-close pointer" onClick={() => setShow(false)}></div>
                        </div>}{
                            imageTop && <img src = {imageTop}  className='card-img-top rounded-1' style = {{ }} alt="a"/>
                        }
                        <div className="modal-body">
                            {children}
                        </div>
                        {footer && <div className="modal-footer">
                            {footer}
                        </div>}
                    </div>
                }
                </div>
            </div>
        </div>
        <div 
            className={clsx("modal-backdrop fade", show && 'show')}
            style = {{backdropFilter: 'blur(5px)'}}
        ></div>
        </>
    )
}
