import React, { useEffect, useId, useRef, useState } from 'react'
import useUpdateEffect from 'hooks/useUpdateEffect';
import clsx from 'clsx';
import 'lib/tom-select';

export const Select = ({
    value,
    setValue,
    light,
    options,
    label,
    labelClassName,
    placeholder,
    width,
    error,
    description,
    dropdownWidth,
    searchInDropdown,
    singleMultiple,
    template,
    hidePlaceholderOnSearch,
    dropup,
    size,
    className
}) => {

    const ref = useRef(null)
    const selectRef = useRef(null)
    const id = useId(); 
  
    useEffect(() => {
        let items = window.HSTomSelect.getItems()
        if(items && items.find((item) => item.inputId === id)) {
            return;
        }
        window.HSTomSelect.init(selectRef.current)
    }, [id, options, value])


    return (
        <div className={className}>
            {label && <label className={clsx(labelClassName, 'form-label ')}>
                {label}
            </label>}
            {description && <small className='text-muted d-block mb-2 mt-n1'>
                {description}
            </small>}
            <div className={clsx('tom-select-custom', error && 'is-invalid')} >
                <select 
                    id={id} 
                    ref = {selectRef}
                    className={clsx(
                        'js-select',
                        'form-select',
                        light && 'form-select-light',
                        size === 'xs' && 'form-select-xs',
                        size === 'sm' && 'form-select-sm',
                        size === 'lg' && 'form-select-lg',
                    )}
                    autoComplete="off" 
                    placeholder={placeholder} 
                    data-hs-tom-select-options={JSON.stringify({
                        "placeholder": {placeholder},
                        "hideSearch": true
                    })}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)}
                    }
                >
                    {options.map((option, index) => (
                        <option  data-option-template={template? template(option) : ''} key={index} value={option.value} >
                            {option.label}
                        </option>
                    ))}
                </select>
                {error && <span className="invalid-feedback d-block">Please select a valid cluster.</span>}
            </div>
        </div>
    )
}
