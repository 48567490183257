/* eslint-disable no-unused-expressions */
import flatpickr from "flatpickr";

window.HSFlatpickr = {
    collection: [],
    dataAttributeName: "data-hs-flatpickr-options",
    defaults: {
        mode: "single",
        dateFormat: "d M Y",
        maxDate: !1,
        locale: {
            firstDayOfWeek: 1,
            weekdays: {
                shorthand: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                longhand: []
            },
            rangeSeparator: " - "
        },
        nextArrow: '<i class="bi-chevron-right flatpickr-custom-arrow"></i>',
        prevArrow: '<i class="bi-chevron-left flatpickr-custom-arrow"></i>',
        disableMobile: !0
    },
    init: function(t, e, i) {
        const o = this;
        let n;
        n = t instanceof HTMLElement ? [t] : t instanceof Object ? t : document.querySelectorAll(t);
        for(let t = 0; t < n.length; t += 1) o.addToCollection(n[t], e, i || n[t].id);
        if(!o.collection.length) return !1;
        o._init()
    },
    getItem(t) {
        return "number" == typeof t ? this.collection[t].$initializedEl : this.collection.find((e => e.id === t)).$initializedEl
    },
    addToCollection(t, e, i) {
        this.collection.push({
            $el: t,
            id: i || null,
            options: Object.assign({}, this.defaults, t.hasAttribute(this.dataAttributeName) ? JSON.parse(t.getAttribute(this.dataAttributeName)) : {}, e)
        })
    },
    _init: function() {
        const t = this;
        for(let e = 0; e < t.collection.length; e += 1) {
            let i, o, n;
            t.collection[e].hasOwnProperty("$initializedEl") || (i = t.collection[e].$el, o = t.collection[e].options, n = i, o.appendTo && (o.appendTo = document.querySelector(o.appendTo)), n instanceof HTMLInputElement || (n = i.querySelector(".flatpickr-input")), n && (i.style.width = 12 * n.value.length + "px"),  console.log(o), t.collection[e].$initializedEl = flatpickr(i, o))
        }
    }
}