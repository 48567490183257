import { useContext } from 'react';
import {WalletLoginContext, HIDE_WALLET_LOGIN, SHOW_WALLET_LOGIN} from '../context/WalletLoginProvider';

let resolveCallback;

function useWalletLogin() {
    
    const [loginWalletState, dispatch] = useContext(WalletLoginContext);
    
    const onWalletLogin = () => {
        closeWalletLogin();
        resolveCallback(true);
    };

    const onCancel = () => {
        closeWalletLogin();
        resolveCallback(false);
    };
    
    const showWalletLogin = (data) => {
        dispatch({
            type: SHOW_WALLET_LOGIN,
            payload: {
                type: data.type,
                data: data.data
            }
        });
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    const closeWalletLogin = () => {
        dispatch({
            type: HIDE_WALLET_LOGIN
        });
    };

    return { 
        showWalletLogin, 
        onWalletLogin, 
        onCancel, 
        loginWalletState 
    };
}

export default useWalletLogin;