import autoAnimate from "@formkit/auto-animate";
import { useState, useCallback, useEffect, useRef } from "react";


const useAnimate = () => {
    
    const ref = useRef();

    useEffect(() => {
        ref.current && autoAnimate(ref.current, {
            duration: 300,
            easing: 'ease-out',
        })
    }, [])

    return { ref };
};


export default useAnimate;