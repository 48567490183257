import useWalletLogin from 'hooks/useWalletLogin';
import React from 'react'
import { createPortal } from 'react-dom';
import SignMessage from '../Models/SignMessage';

export const WalletLoginDialog = () => {

    const { onCancel, loginWalletState } = useWalletLogin();

    const portalElement = document.getElementById('portal');
    let type = loginWalletState?.type;
    let data = loginWalletState?.data;

    let component = <></>;
    if(!loginWalletState?.show) return createPortal(component, portalElement);

    component = <SignMessage type = {type} data = {data} show = {loginWalletState.show} setShow = {onCancel}/>
    return createPortal(component, portalElement);

}
