import clsx from 'clsx';
import PropTypes from 'prop-types';


const colors = {
    primary: 'primary',
    danger: 'danger',
    secondary: 'secondary',
    success: 'success',
    warning: 'warning',
    info: 'info',
    light: 'light',
    dark: 'dark',
    white: 'white'
}

const variants = {
    ghost: 'ghost',
    soft: 'soft',
    outline: 'outline',
}



export const Button = ({
    loading,
    variant,
    color,
    startIcon,
    endIcon,
    size,
    className,
    type = 'button',
    children,
    pill,
    block,
    onClick,
    ...props
}) => {
    return (
        <div
            type = {type}
            disabled = {loading}
            onClick = {loading ? null : onClick}
            className={clsx(
                'btn',
                'btn-' + (variant ? variants[variant] + '-' : '') + (colors[color] || colors.primary),
                size && 'btn-' + size,
                block && 'w-100',
                pill && 'rounded-pill',
                className,
                'd-inline-flex align-items-center justify-content-center'
            )}
            {...props}
        >
            {loading && <span className='spinner-border spinner-border-sm me-2'></span>}
            {(!loading && startIcon) && <i className={clsx(startIcon, 'me-2')}></i>}
            {children}
            {(!loading && endIcon) && <i className={clsx(endIcon, 'ms-2')}></i>}
        </div>
  );
};


Button.propTypes = {
    loading: PropTypes.bool,
    variant: PropTypes.oneOf(Object.keys(variants)),
    color: PropTypes.oneOf(Object.keys(colors)),
    startIcon: PropTypes.string,
    endIcon: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'lg']),
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    children: PropTypes.node,
    pill: PropTypes.bool,
    block: PropTypes.bool,
}


