
export const ErrorFallback = () => {
    return (
        <div className="container">
            <div className="position-absolute top-0 start-0  "  style = {{maxWidth: '50vw'}}>
                <img className="" src="https://i.pinimg.com/originals/76/9c/b6/769cb65fa3b3efe5eca16cf29a280d7e.jpg" alt="aa" style = {{maxWidth: '50vw', height: '100vh', objectFit: 'cover'}} />
            </div>
            <a className="position-absolute top-0 start-0 p-4" href="./index.html">
                <img className="avatar avatar-xxl avatar-4x3 avatar-centered" src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.svg"} alt="aa" data-hs-theme-appearance="default" />
                <img className="avatar avatar-xxl avatar-4x3 avatar-centered" src={process.env.PUBLIC_URL + "/assets/svg/logos-light/logo.svg"} alt="aa" data-hs-theme-appearance="dark" />
            </a>
            <div className="footer-height-offset d-flex justify-content-center align-items-center flex-column position-relative">
                <div className="row justify-content-center align-items-sm-center w-100">
                    <div className="col-9 col-sm-6 col-lg-6">
                        <div className="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-6 text-center text-sm-start px-10">
                        <h1 className="display-1 mb-0">500</h1>
                        <p className="lead">The server encountered an internal error or misconfiguration and was unable to complete your request.</p>
                        <span className="btn btn-primary" onClick={() => window.location.reload()}>Reload page</span>
                    </div>
                </div>
            </div>
        </div>
  );
};

