import React from 'react'

export const BorderedCard = ({children}) => {
  return (
    <div className='position-relative'>
        <div className="card border border-dark border-2 card-lg mb-5" style = {{zIndex: 2}}>
            {children}
        </div>
        <div className='position-absolute h-100 w-100' style = {{zIndex: 1, top: '10px', left: '10px', transform: 'rotate(1deg)'}}>
            <div className='card border border-dark border-2 h-100 w-100'>
            </div>
        </div>
        <div className='position-absolute h-100 w-100' style = {{zIndex: 0, top: '20px', left: '20px', transform: 'rotate(2deg)'}}>
            <div className='card border border-dark border-2 h-100 w-100'>
            </div>
        </div>
    </div>
  )
}
