import { Navigate } from "react-router-dom";
import { lazyImport } from 'utils/lazyImport';

// const { Quest } = lazyImport(() => import('features/Quest'), 'Quest');


const { VerifyRoutes } = lazyImport(() => import('features/Verification'), 'VerifyRoutes');


export const publicRoutes = [
  {
    path: '/verify/*',
    element: <VerifyRoutes />,
  },
  { path: '*', element: <Navigate to="/" /> },
];
