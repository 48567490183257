import { useLayoutEffect } from "react";

function useLockBodyScroll(show) {

	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow;
		if(show){
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "visible";
		}
	  	return () => (document.body.style.overflow = originalStyle);
	}, [show]); 
  }

export default useLockBodyScroll;