const {axios} = require('lib/axios');

export const loginWithDiscord = async (data) => {
    const response = await axios.post('auth/login', data);
    return response;
}


export const loginWithTwitter = async (data) => {
    const response = await axios.post('auth/login', data);
    return response;
}

export const loginWithWallet = async (data) => {
    const response = await axios.post('auth/login', data);
    return response;
}

export const getUser = async () => {
    try{
        const response = await axios.get('user/fetch');
        return response;
    } catch(err){
        return null;
    }
}


export const walletRedirect = async (data) => {
    const response = await axios.post('auth/wallet/redirect', data);
    return response;
}

export const discordRedirect = async (data) => {
    const response = await axios.post('auth/discord/redirect', data);
    return response;
}

export const twitterRedirect = async (data) => {
    const response = await axios.post('auth/twitter/redirect', data);
    return response;
}

export const logout = async () => {
    const response = await axios.get('/auth/logout');
    return response;
}
