/* eslint-disable no-unused-expressions */
import Quill from "quill";

window.HSQuill = {
    collection: [],
    dataAttributeName: "data-hs-quill-options",
    defaults: {
        theme: "snow",
        attach: !1
    },
    init(t, e, i) {
        const o = this;
        let n;
        n = t instanceof HTMLElement ? [t] : t instanceof Object ? t : document.querySelectorAll(t);
        for(let t = 0; t < n.length; t += 1) o.addToCollection(n[t], e, i || n[t].id);
        if(!o.collection.length) return !1;
        o._init()
    },
    addToCollection(t, e, i) {
        const o = this;
        this.collection.push({
            $el: t,
            id: i || null,
            options: Object.assign({}, o.defaults, t.hasAttribute(o.dataAttributeName) ? JSON.parse(t.getAttribute(o.dataAttributeName)) : {}, e)
        })
    },
    getItems() {
        const t = this;
        let e = [];
        for(let i = 0; i < t.collection.length; i += 1) e.push(t.collection[i].$initializedEl);
        return e
    },
    getItem(t) {
        return "number" == typeof t ? this.collection[t].$initializedEl : this.collection.find((e => e.id === t))
            .$initializedEl
    },
    _init() {
        const t = this;
        for(let e = 0; e < t.collection.length; e += 1) {
            let i, o;
            t.collection[e].hasOwnProperty("$initializedEl") || (i = t.collection[e].options, o = t.collection[e].$el, t.collection[e].$initializedEl = new Quill(o, i), o.classList.add("hs-quill-initialized"), this.toolbarBottom(i, t.collection[e].$initializedEl))
        }
    },
    toolbarBottom: function(t, e) {
        if(t.toolbarBottom) {
            const i = e.container,
                o = i.previousElementSibling;
            if(i.parentElement.classList.add("ql-toolbar-bottom"), t.attach) {
                document.querySelector(t.attach)
                    .addEventListener("shown.bs.modal", (() => {
                        i.style.paddingBottom = o.offsetHeight + "px"
                    }))
            } else i.style.paddingBottom = o.offsetHeight + "px";
            o.style.position = "absolute", o.style.width = "100%", o.style.bottom = 0
        }
    }
}