
export const Avatar = ({image, imageClassName, size='sm', fullName, name, circle=true, color='primary', soft, status, statusColor='success'}) => { 
    return (
        <span className={`avatar avatar-${soft ? 'soft-' : ''}${color} avatar-${size} ${circle && 'avatar-circle'}`} >
            {!image && <span className={`avatar-initials`}>{name ? (fullName ? name : name[0]) : ''}</span>}
            {image && <img data-tag="allowRowEvents"  className={`avatar-img ${imageClassName}`} src={image} alt="Avatar" />}
            {status && <span data-tag="allowRowEvents"  className={`avatar-status avatar-${size}-status avatar-status-${statusColor}`}></span>}
        </span>
    ); 
};

