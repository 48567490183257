import { QueryClient } from '@tanstack/react-query';

const queryConfig = {
    defaultOptions: {
        queries: {
          staleTime: Infinity,
          useErrorBoundary: true,
            refetchOnWindowFocus: true,
            retry: false,
        },
    },
};

export const queryClient = new QueryClient(queryConfig);

