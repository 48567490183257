import {useReducer, createContext} from "react";

export const SHOW_WALLET_LOGIN = 'SHOW_WALLET_LOGIN';
export const HIDE_WALLET_LOGIN = 'HIDE_WALLET_LOGIN';

export const WalletLoginContext = createContext({});

const initialState = {
    show: false,
    type: '', //['all', 'twitter', 'wallet', 'connect']
    data: {}, //all the metadata that needs to be sent to login APIs
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_WALLET_LOGIN:
            return {
                show: true,
                type: action.payload.type,
                data: action.payload.data
            };
        case HIDE_WALLET_LOGIN:
            return initialState;
        default:
            return initialState;
    }
};

export const WalletLoginContextProvider = ({ children }) => {
    
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <WalletLoginContext.Provider value={[state, dispatch]}>
            {children}
        </WalletLoginContext.Provider>
    );
};