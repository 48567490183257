import React, { useEffect } from 'react'

const URL = "https://images.unsplash.com/photo-1614850716626-873413eb7c1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"

export const Background = ({children, maxWidth}) => {
	
	useEffect(() => {
		const ele = document.getElementsByTagName("BODY")[0];
		ele.setAttribute("class", "");
	}, [])



	return (
		<>
		<div className='min-vh-100 d-flex flex-column align-items-center justify-content-center'>
			<div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{zIndex: '-1', height: "32rem", backgroundImage:`url(${URL})`}}>
				<div className="shape shape-bottom zi-1">
					<svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
						<polygon fill="#fff" points="0,273 1921,273 1921,0 "></polygon>
					</svg>
				</div>
			</div>
			<div className="mx-auto" style={{maxWidth: maxWidth ? maxWidth : "30rem"}}>
				{children}
			</div>
			<span className="mx-auto mt-4 text-center">
				<div className=" text-body mb-3">Trusted by the world's best teams</div>
				<img className="zi-2" src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.svg"} alt="Imag" style={{width: "8rem"}} />
			</span>
		</div>
		</>
  	)
}
