import { axios } from 'lib/axios'

export const submitWallet = (data) => {
    try {
        let response = axios.post("/gating/submit-wallet", data);
        return response;
    } catch(err){
        throw err;
    }
}

export const twitterRedirectApi = (data) => {
    try {
        let response = axios.post("/gating/twitter/oauth/callback", data);
        return response;
    } catch(err){
        return new Error(err)
    }
}