import Axios from 'axios';

import { API_URL } from 'config';
// import { useNotificationStore } from '@/stores/notifications';


export const axios = Axios.create({
    baseURL: API_URL + '/api/qv1/',
    withCredentials: true,
});


axios.interceptors.response.use((response) => {
    return response.data;
  }, (error) => {
        throw error;
    }
);

