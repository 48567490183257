/* eslint-disable no-unused-expressions */
import TomSelect from "tom-select";
// import 'tom-select/dist/css/tom-select.bootstrap5.css'


window.HSTomSelect = {
    dataAttributeName: "data-hs-tom-select-options",
    defaults: {
        dropdownWrapperClass: "tom-select-custom",
        searchInDropdown: !0,
        plugins: ["change_listener", "hs_smart_position"],
        hideSelected: !1,
        render: {
            option: function(t, e) {
                return t.optionTemplate || `<div>${t.text}</div>>`
            },
            item: function(t, e) {
                return t.optionTemplate || `<div>${t.text}</div>>`
            }
        }
    },
    collection: [],
    init(t, e, i) {
        const o = this;
        let n;
        n = t instanceof HTMLElement ? [t] : t instanceof Object ? t : document.querySelectorAll(t);
        for(let t = 0; t < n.length; t += 1) o.addToCollection(n[t], e, i || n[t].id);
        if(!o.collection.length) return !1;
        o._init()
    },
    addToCollection(t, e, i) {
        const o = this;
        this.collection.push({
            $el: t,
            id: i || null,
            options: Object.assign({}, o.defaults, t.hasAttribute(o.dataAttributeName) ? JSON.parse(t.getAttribute(o.dataAttributeName)) : {}, e)
        })
    },
    getItems() {
        const t = this;
        let e = [];
        for(let i = 0; i < t.collection.length; i += 1) e.push(t.collection[i].$initializedEl);
        return e
    },
    getItem(t) {
        return "number" == typeof t ? this.collection[t].$initializedEl : this.collection.find((e => e.id === t)).$initializedEl
    },
    _init() {
        const t = this;
        for(let e = 0; e < t.collection.length; e += 1) {
            let i, o;
            t.collection[e].hasOwnProperty("$initializedEl") || (i = t.collection[e].options, o = t.collection[e].$el, i.plugins.hasOwnProperty("hs_smart_position") && !o.closest(".modal") && (i.dropdownParent = "body"), o.hasAttribute("multiple") && (i.plugins = [...i.plugins, "remove_button"]), i.searchInDropdown && (i.plugins = [...i.plugins, "dropdown_input"]), TomSelect.define("hs_smart_position", (function(t) {
                this.hook("after", "setup", (function() {
                    this.$menu = this.dropdown_content.parentElement, this.on("dropdown_open", (t => {
                        const e = t.getBoundingClientRect(),
                            o = this.wrapper.getBoundingClientRect();
                        e.bottom > window.innerHeight && (t.style.top = parseInt(t.style.top) - (this.control.clientHeight + t.clientHeight + 10) + "px"), t.style.opacity = 0, setTimeout((() => {
                            const n = parseInt(t.style.width);
                            n > o.width && i.dropdownLeft && (t.style.left = parseInt(t.style.left) - Math.abs(e.width - n) + "px"), t.style.opacity = 1
                        }))
                    })), window.addEventListener("scroll", (() => function(t) {
                        const e = t.$menu.getBoundingClientRect();
                        e.bottom > window.innerHeight ? t.$menu.style.top = `-${t.$menu.clientHeight + 10}px` : e.top < 0 && (t.$menu.style.top = null)
                    }(this)))
                }))
            })), t.collection[e].$initializedEl = new TomSelect(o, i), i.hideSearch && t.hideSearch(t.collection[e].$initializedEl, i), i.disableSearch && t.disableSearch(t.collection[e].$initializedEl, i), i.width && t.width(t.collection[e].$initializedEl, i), i.singleMultiple && t.singleMultiple(t.collection[e].$initializedEl, i), i.hidePlaceholderOnSearch && t.hidePlaceholderOnSearch(t.collection[e].$initializedEl, i), i.create && t.openIfEmpty(t.collection[e].$initializedEl, i), i.hideSelectedFromField && t.hideSelectedFromField(t.collection[e].$initializedEl, i), i.dropdownWidth && t.dropdownWidth(t.collection[e].$initializedEl, i), t.renderPlaceholder(t.collection[e].$initializedEl, i), t.wrapContainer(t.collection[e].$initializedEl, i))
        }
    },
    hideSearch(t, e) {
        t.control_input.parentElement.removeChild(t.control_input)
    },
    disableSearch(t, e) {
        t.control_input.readOnly = !0
    },
    singleMultiple(t, e) {
        t.control.classList.add("hs-select-single-multiple");
        const i = (t.control_input.getAttribute("placeholder") || e.placeholder).replace(/(<([^>]+)>)/gi, ""),
            o = e => {
                e.target.closest("[data-selectable].selected") && (e.target.classList.remove("selected"), setTimeout((() => {
                    t.removeItem(e.target.getAttribute("data-value"), !1), t.refreshItems()
                })))
            },
            n = e => {
                if(!t.wrapper.querySelector(".ts-selected-count")) {
                    const e = document.createElement("span");
                    e.classList.add("ts-selected-count"), t.wrapper.querySelector(".ts-control").appendChild(e)
                }
                return t.wrapper.querySelector(".ts-selected-count").innerHTML = e
            };
        t.items.length && (e.searchInDropdown ? n(t.items.length ? `${t.items.length} item(s) selected` : i) : t.control_input.setAttribute("placeholder", `${t.items.length} item(s) selected`)), t.on("dropdown_open", (t => {
            t.addEventListener("mouseup", o)
        })), t.on("dropdown_close", (t => {
            window.removeEventListener("mouseup", o)
        })), t.on("item_add", (() => {
            t.items.length && (e.searchInDropdown ? n(`${t.items.length} item(s) selected`) : t.control_input.setAttribute("placeholder", `${t.items.length} item(s) selected`))
        })), t.on("item_remove", (() => {
            t.items.length ? e.searchInDropdown ? n(`${t.items.length} item(s) selected`) : t.control_input.setAttribute("placeholder", `${t.items.length} item(s) selected`) : e.searchInDropdown ? n(i) : t.control_input.setAttribute("placeholder", i)
        }))
    },
    width(t, e) {
        t.wrapper.style.maxWidth = e.width
    },
    hidePlaceholderOnSearch(t, e) {
        const i = (t.control_input.getAttribute("placeholder") || e.placeholder).replace(/(<([^>]+)>)/gi, "");
        i && (t.on("dropdown_open", (() => {
            t.control_input.setAttribute("placeholder", "")
        })), t.on("dropdown_close", (() => {
            t.control_input.setAttribute("placeholder", i)
        })))
    },
    openIfEmpty(t, e) {
        t.control_input.addEventListener("focus", (() => {
            t.$menu.querySelector(".option") || (t.open(), setTimeout((() => {
                t.$menu.style.display = "block", t.$menu.querySelector(".ts-dropdown-content").append(t.render("no_results"))
            }), 10))
        }))
    },
    hideSelectedFromField(t, e) {
        // t.on("item_select", onSelect), t.on("item_add", onSelect)
    },
    dropdownWidth(t, e) {
        t.on("dropdown_open", (() => t.$menu.style.width = e.dropdownWidth))
    },
    width(t, e) {
        t.wrapper.style.width = e.width
    },
    renderPlaceholder(t, e) {
        if(e.singleMultiple || t.items.length) return;
        const i = t.input.getAttribute("placeholder") || e.placeholder;
        if(e.searchInDropdown && !e.hideSelected) {
            let e = null;
            const o = function() {
                    if(e = t.wrapper.querySelector(".ts-custom-placeholder"), t.items.length && e) return e.parentElement && e.parentElement.removeChild(e), e = null;
                    t.items.length || e || n()
                },
                n = function() {
                    t.items.length || (t.wrapper.querySelector(".ts-control").innerHTML = `<span class="ts-custom-placeholder">${i}</span>`, e = t.wrapper.querySelector(".ts-custom-placeholder"))
                };
            n(), t.on("change", o)
        }
        i && (t.control_input.offsetParent ? function(e) {
            t.control_input.setAttribute("placeholder", e.replace(/(<([^>]+)>)/gi, ""))
        }(i) : function(e) {
            const i = () => {
                t.control.innerHTML = `<div class="ts-custom-placeholder">${e}</div>`
            };
            i(), t.on("change", (() => {
                t.items.length && (() => {
                    const e = t.wrapper.querySelector(".items .ts-custom-placeholder");
                    e && e.parentElement && e.parentElement.removeChild(e)
                })(), t.items.length || i()
            }))
        }(i))
    },
    wrapContainer(t, e) {
        var i = document.createElement("div");
        i.className += e.dropdownWrapperClass, t.$menu.parentNode.insertBefore(i, t.$menu), i.appendChild(t.$menu)
    }
}