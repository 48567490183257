import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorFallback } from 'components/Layout';
import { FullPageLoading } from 'components/Elements';
import { Toaster } from 'react-hot-toast';
import { queryClient } from 'lib/react-query';
// import { AuthProvider } from 'context/AuthProvider';
import {ThemeProvider} from 'context/ThemeProvider';
import { LoginContextProvider } from 'context/LoginProvider';
import { Web3ReactProvider } from "@web3-react/core";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// import connectors from "utils/wallet/connectors";
import { Web3Provider } from "@ethersproject/providers";
import { WalletLoginContextProvider } from 'context/WalletLoginProvider';
import { WalletLoginDialog } from 'components/Login/Dialogs/WalletLoginDialog';


function getLibrary(provider) {
    return new Web3Provider(provider);
}

export const AppProvider = ({ children }) => {

    return (
        <HelmetProvider>
            <Router>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <Web3ReactProvider getLibrary={getLibrary}>
                            <ThemeProvider>
                                <React.Suspense
                                    fallback={<FullPageLoading />}
                                >
                                    <WalletLoginContextProvider>
                                        <LoginContextProvider>
                                            <Toaster />
                                            {children}
                                            <WalletLoginDialog />
                                        </LoginContextProvider>
                                    </WalletLoginContextProvider>
                                </React.Suspense>
                            </ThemeProvider>
                        </Web3ReactProvider>
                    </QueryClientProvider>
                </ErrorBoundary>
            </Router>
        </HelmetProvider>
    );
};


