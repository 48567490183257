import { useWeb3React } from "@web3-react/core";
import { Button } from "components/Elements";
import { CenteredModal } from "components/Elements";
import useLocalStorage from "hooks/useLocalStorage";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Injected, CoinbaseWallet } from "utils/wallet/connectors/connectors";
import {WALLET_AUTH_MESSAGE} from "types"
import { walletRedirect } from "services/auth";
import useRouter from "hooks/useRouter";
// import { useAuth } from "context/AuthProvider";
import { PUBLIC_URL } from "config";
import { submitWallet } from "services/tokenGates";


const SignMessage = ({type, data, show, setShow}) => {

    const {navigate} = useRouter()
    
    const { 
        token,
        guildId,
    } = data;

    const {
        library,
        account,
        activate,
        deactivate,
        active,
        error: error_,
    } = useWeb3React();
    
    const message = WALLET_AUTH_MESSAGE
    const [wallet, setWallet] = useLocalStorage('wallet', {provider: '', address: ''})
    const [status, setStatus] = useState('initial')
    const [error, setError] = useState("");

    useEffect(() => {
        if(error_?.message === 'The user rejected the request.'){
            toast.error('Please connect your wallet')
            setError('The user rejected the request.')
        }
    }, [error_])
    
    const signMessage = async () => {
        setError(false)
        if (!library) return;
        try {
            const signature = await library.provider.request({
                method: "personal_sign",
                params: [message, account]
            });
            return {
                message,
                signature
            }
        } catch (error) {
            throw error;
        }
    };

    const disconnect = async () => {
        window.localStorage.setItem("wallet", undefined);
        deactivate();
    };

    useEffect(() => {
        loginLifecycle('connect')
    }, [])


    useEffect(() => {
        if(account && active){
            console.log(`account: ${account} active: ${active}`)
            loginLifecycle('signature')
        }
    }, [account, active])


    const loginLifecycle = async (step, retryWithAnother) => {
        try {
            if(step === 'connect'){
                console.log('connect', type, Injected, CoinbaseWallet);
                setStatus('activating')
                if(retryWithAnother) await disconnect()
                await activate(type === 'metamask' ? Injected : CoinbaseWallet)
                console.log('connected', activate);

            }
            if(step === 'signature'){
                setStatus('signature')
                setWallet({provider: type, address: account})
                setStatus('loading')
                
                await submitWallet({address: account, token:token})
                setStatus('completed')
                toast.success('Wallet connected successfully, redirecting to discord in 3 seconds')
                setTimeout(() => {
                    window.location.href = `https://discordapp.com/channels/${guildId}`
                }, 3000)
                setShow(false)
            }
            if(step === 'disconnect'){
                disconnect()
            }
        } catch (error) {
            console.log('error')
            setError(error?.response?.data?.message || error.message)
            toast.error(error.response?.data?.message || error.message)
            console.log(error)
        }
    }



    return (
        <CenteredModal show = {show} setShow = {setShow}>
            <div className="d-flex flex-column align-items-center justify-content-center" style = {{minHeight: '300px'}}>
            {(error) && <div>
                <div class='px-6 text-center mb-4 mt-5'>
                    <img src = {"/assets/svg/illustrations/error.png"} class='img-fluid mb-3 mr-2' style = {{height: '100px'}} alt = "web3" />
                    <h2>Error connecting wallet</h2>
                    <div className="fs-3">{error}</div>
                    <Button onClick={() => loginLifecycle('connect', true)} className="mt-3 w-50">Retry {error.includes('another user') ? 'another wallet' : ''}</Button>
                </div>
            </div>}
            {!error && <div class=''>
                {status === 'activating' && <div class='px-6 text-center mb-6 mt-5'>
                    <img src = "/assets/metamask.png" class='img-fluid' style = {{height: '150px'}} alt = "web3" />
                    <div class='h2 mb-1'>Waiting for confirmation from Metamask</div>
                    <span class='spinner-border spinner-border-lg mt-5'></span>
                </div>}
                {status === 'signature' && <div class='px-6 text-center mb-4 mt-5'>
                    <img src = "/assets/development.svg" class='img-fluid mb-3 mr-2' style = {{height: '200px'}} alt = "web3" />
                    <div class='h2 mb-1'>Please sign a verification message</div>
                    <span class='spinner-border spinner-border-lg mt-5'></span>
                </div>}
                {status === 'loading' && <div class='px-6 text-center my-4'>
                    <img src = "/assets/email-verification.svg" class='img-fluid mb-3 mr-6 pr-6' style = {{height: '200px'}} alt = "web3" />
                    <div class='h2 mb-1'>Your wallet has been successfully added.</div>
                    <span class='spinner-border spinner-border-lg mt-5'></span>
                </div>}
            </div>}
            </div>
        </CenteredModal>
    );

};

export default SignMessage;
