export const THEME_TYPE = {
    LIGHT_MODE: 'LIGHT_MODE',
    DARK_MODE: 'DARK_MODE',
    SYSTEM_DEFAULT: 'SYSTEM_DEFAULT',
}

export const DASHBOARD_VIEW_TYPE = {
    MINI_VIEW: 'MINI_VIEW',
    CLOSED_VIEW: 'CLOSED_VIEW',
    FULL_VIEW: 'FULL_VIEW',
}

export const SCREEN_TYPE = {
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0,
}

export const TEMPLATE_FAMILY = {
    NONE: "NONE",
    DISCORD: "DISCORD",
    TWITTER: "TWITTER",
    WALLET: "WALLET",
}

export const WALLET_AUTH_MESSAGE = "Please sign this message to authenticate your wallet."